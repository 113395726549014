<template>
  <downloadexcel
    :fetch="getRelatos"
    :fields="json_fields"
    name="Relatos.xls"
    type="xls"
  >
    <el-button
      type="info"
      icon="el-icon-download"
    >Exportar</el-button>
  </downloadexcel>
</template>

<script>
/* eslint-disable */
import downloadexcel from 'vue-json-excel';
import dataFormat from 'moment'

export default {
  name: 'App',
  components: {
    downloadexcel
  },
  props: {
    relatos: {
      type: Array
    },
    urlExportacao: {
      type: String
    }
  },
  data () {
    return {
      situacao: {
        nome: {
          1: 'Pendente',
          2: 'Cancelado',
          3: 'Tratado',
        },
      },
      json_fields: {
        Empresa: 'empresa_nome',
        'Tipo de Relato': 'tipo_relato_nome',
        Grupo: 'grupo_nome',
        Item: 'item_nome',
        'Área do Relatado': 'area_relatado_nome',
        'Relatante': 'nome_do_relatante',
        Local: 'local',
        Placa: 'placa',
        Funcionário: 'funcionario',
        'Área do Relatante': 'area_relatante_nome',
        'Função': 'funcao',
        'Situação': {
          field: 'situacao',
          callback: (status) => {
            return this.situacao.nome[status];
          }
        },
        'Data Cadastro': {
          field: 'hora_cadastro',
          callback: (data) => {
            return data ? dataFormat(data).format('DD/MM/YYYY') : '';
          }
        },
        'Data do Ocorrido': {
          field: 'hora',
          callback: (data) => {
            return dataFormat(data).format('DD/MM/YYYY');
          }
        },
        'Hora do Ocorrido': {
          field: 'hora',
          callback: (data) => {
            return dataFormat(data).format('HH:mm');
          }
        },
        'Descrição': 'descricao',
        'Observações': {
          callback: (acao) => {
            return acao.comentario || acao.comentario_finalização
          }
        },
      },
      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8'
          }
        ]
      ]
    };
  },
  methods: {
    async getRelatos () {
      this.$swal({
        title: 'Preparando a Exportação'
      });
      this.$swal.showLoading()
      const result = await this.$axios.get(this.urlExportacao);
      const relatosDaEmpresa = await result.data;
      this.$swal.close();
      return relatosDaEmpresa;
    },
  }
};
</script>
